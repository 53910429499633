<template>
    <q-layout  view="hHh lpR fff"> <!-- :theme="store.theme" -->
        <HeaderMain />
        <q-page-container fluid>
            <loader v-if="store.loader" />
            <q-page>
                <div class="q-mb-sm">
                    <div class="header-container">
                        <QTabs
                            indicator-color="red"
                            no-caps
                            class="tabs"
                            :dense="$q.screen.lt.sm"
                            content-class="tabs-content"
                        >
                            <QRouteTab
                                to="/collections/connected"
                                exact
                                label="connected"
                                class="tab"
                                content-class="tab-content"
                                active-class="active-tab"
                            />
                            <QRouteTab
                                to="/collections/my"
                                exact
                                label="personal"
                                class="tab"
                                content-class="tab-content"
                                active-class="active-tab"
                            />
                        </QTabs>
                       <!-- <div
                            class="button-container"
                            :class="{ active: showDescription }"
                        >
                            <QBtn
                                icon="mdi-information-variant"
                                round
                                outline
                                size="sm"
                                @click="showDescription = !showDescription"
                            />
                        </div> -->
                    </div>
                    <div v-if="showDescription" class="description-container">
                        <div class="description text-h6">
                            Create and share video playlists on Lucid.
                            On any video page, click
                            <span class="text-no-wrap"
                                >+ Add to Collection</span
                            >
                            to get started!
                        </div>
                    </div>
                </div>
                <slot />
            </q-page>
        </q-page-container>
        <FooterMain />
    </q-layout>
</template>
<script setup lang="ts">
import { useStore } from '@/store/store';
const store = useStore();

const showDescription = ref(false);
</script>
<style scoped lang="scss">
@import '~/public/assets/pages';
.header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    @media (max-width: $breakpoint-small) {
        padding-top: 44px;
        padding-bottom: 15px;
        opacity: 0.6;
    }
}
.button-container {
    padding: 5px;
    padding-bottom: 15px;
    margin-right: 10px;
    margin-left: 8px;
    border-radius: 15px;
    border-bottom-right-radius: 60px;
    &.active {
        background-color: rgba(27, 232, 255, 0.15);
    }
}
.description-container {
    margin-left: auto;
    max-width: 830px;
    padding: 0 20px;
}
.description {
    background-color: rgba(27, 232, 255, 0.15);
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 1.5;
    text-align: right;
    span {
        font-weight: 700;
    }
}
.tabs {
    margin-bottom: 8px;
    :deep(.tabs-content) {
        display: flex;
        justify-content: space-between;
    }
    :deep(.q-tab__indicator) {
        background-color: #172d30;
        z-index: -1;
        border-radius: 25px;
        height: calc(100% + 3px);
        bottom: -1px;
        left: -1px;
        right: -3px;
    }
}
.tab {
    flex-grow: 1;
    border: 2px solid #22575e;
    &:first-child {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-right-width: 0;
    }
    &:last-child {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-left-width: 0;
    }
    color: #22575e;
    min-height: 36px;
    :deep(.tab-content) {
        padding: 0;
    }

    :deep(.q-tab__label) {
        font-size: 15px;
        font-weight: regular;
    }
}
.active-tab {
    color: #26b5c6;
}
@media (min-width: $breakpoint-small) {
    .description {
        display: inline-block;
        font-size: 18px;
        padding: 10px 30px;
    }
}
</style>
